jQuery(document).ready(function () {


    $("#submit").click(function(e) {
        e.preventDefault();
        var btn = $(this);


        var go = true;
        $( "input,textarea" ).each(function( index ) {
                if($(this).val().trim()==''){
                    $(this).addClass("error-border");
                    go = false;
                }else{
                    $(this).removeClass('error-border');
                }
          });

        if(go){

            btn.val('enviando...');
            $.ajax({
                type: "POST",
                url: "email.php",
                data: $('form').serialize(),
                dataType: 'json',
                complete:function(data){
                    btn.val('enviar');
    
                    $('.return').html(data.responseJSON.message);
                   
                }
            });
        }

      
    });

    $("#submit-simulacao").click(function(e) {
        e.preventDefault();
        var btn = $(this);
        console.log("aqui");
        var go = true;
        $( ".input-simulacao" ).each(function( index ) {
                if($(this).val().trim()==''){
                    console.log($(this).val());
                    $(this).addClass("error-border");
                    go = false;
                }else{
                    $(this).removeClass('error-border');
                }
          });
          
        if(go){
            console.log("aqui1");
            btn.val('enviando...');
            $.ajax({
                type: "POST",
                url: "email-simulacao.php",
                data: $('form').serialize(),
                dataType: 'json',
                complete:function(data){
                    btn.val('enviar');
    
                    $('.return-simu').html(data.responseJSON.message);
                   
                }
            });
        }

      
    });


    var $scrollThese = $('.scrollme-desktop');
    var $animateThese = $('.animateme-desktop');
  
    // If user is on mobile, exit the function
    if (!isMobile.any) {
        // Initialize scrollme if desktop client detected
        $scrollThese.removeClass('scrollme-desktop').addClass('scrollme'); 
        $animateThese.removeClass('animate-desktop').addClass('animateme');
        scrollme.init();
    }
  

    $(window).on('load', function(){
        $("#loader").delay(200).fadeOut("slow");
        $('.modal-projeto').hide().css('opacity',1);
    });

    // $('.projetos__item').on('click',function(){
    //     $(this).find('.modal-projeto').fadeIn();
    //     $('.overlay').fadeIn();
    //     $('#section-projetos').css('transform','none');
    //     $('body,html').css('overflow','hidden');
    // });

    // $('.overlay').on('click',function(){
    //     $(this).fadeOut();
    //     $('.modal-projeto').fadeOut();
    //     $('body').css('overflow-x','hidden');
    //     $('body,html').css('overflow-y','visible');
    // });

    $('.header__links--hamburguer').on('click', function () {
        $('.hamburguer')
            .toggleClass('hamburguer--openned');

        $(this)
            .siblings('.header__links--mobile')
            .slideToggle('fast')
    });

    $('a').on('click', function (event) {
        event.preventDefault();
        var section = $(this).attr('href');

        window.scrollTo({
            top: $(section).offset().top,
            left: 0,
            behavior: "smooth"
        });
    })


    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        lazyLoad:true,
        items:1,
        nav:true,

    });


    //-------------VIDEO POPUP------------------------------
    $(document).ready(function() {
        //if(localStorage.getItem('popState') != 'shown'){
            $("#popup").delay(2000).fadeIn();
            //localStorage.setItem('popState','shown');
        //}
    
        $('#popup-close').click(function(e) // You are clicking the close button
        {
            $('#popup').remove(); // Now the pop up is hiden.
        });
            //$('#popup').click(function(e) 
        //{
            //$('#popup').remove(); 
        //});
    });
});
